.article-editor-page-editor-container {
    width: 100%;
}

.reset-font-style {
    font-family: initial;

    * {
        font-family: unset;
    }
}

.publish-options {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;


    .publish-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        transition: all 0.3s ease;

        &:hover {
            color: #3F77F8;
        }

        svg {
            width: 0.5rem;
            height: 0.5rem;
            margin-left: 0.5rem;
            transition: transform 0.3s ease;
        }

        @media screen and (max-width: 534px) {
            padding: 0.5rem;
        }

    }

    .publish-dropdown {
        position: absolute;
        top: 100%;
        left: 1.5rem;
        z-index: 10;
        display: none;
        background-color: #fff;
        box-shadow: 0px 0.25rem 1.75rem 0.5rem rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;
        padding: 0.5rem 0;
        margin-top: 0.5rem;

        &.show {
            display: block;
        }

        .publish-item {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0.75rem 1rem;
            font-size: 0.875rem;
            font-weight: 500;
            color: #333;
            background-color: #fff;
            border: none;
            border-radius: 0.25rem;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #f0f0f0;
                color: #3F77F8;

                svg {
                    color: #3F77F8;
                }
            }

            svg {
                width: 1rem;
                height: 1rem;
                margin-right: 0.5rem;
            }
        }
    }

    .publish-dropdown {
        width: 20rem;

        .card-header {
            padding: 1rem;
            border-bottom: 1px solid #E5E5E5;
            font-size: 1.1rem;
            font-weight: 500;
        }

        .card-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: .75rem;
            padding-bottom: 0;

            .radio-group {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                .form-control-label {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    font-size: 1rem;
                }

                .text-muted-foreground {
                    font-size: 0.875rem;
                    color: #6c757d;
                }
            }

            .grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 0.5rem;

                .form-group {
                    display: flex;
                    flex-direction: column;
                    gap: 0.25rem;

                    label {
                        font-size: 0.875rem;
                        font-weight: 500;
                    }

                    .form-control {
                        padding: 0.5rem;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        font-size: 1rem;
                    }
                }
            }

            .space-y-2 {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                label {
                    font-size: 0.875rem;
                    font-weight: 500;
                }

                .form-control {
                    padding: 0.5rem;
                    border: 1px solid #ced4da;
                    border-radius: 0.25rem;
                    font-size: 1rem;
                }
            }
        }

        footer {
            padding: 1rem;
            display: flex;
            justify-content: flex-end;

            .btn {
                padding: 0.5rem 1rem;
                background-color: #3F77F8;
                color: #fff;
                border: none;
                border-radius: 0.25rem;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #2A5CD5;
                }

                &:disabled {
                    background-color: #B0C4FF;
                    cursor: not-allowed;
                }
            }
        }
    }
}

.save-collapse {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    svg.collapse-button {
        cursor: pointer;

        &:hover path {
            fill: #3F77F8 !important;
            fill-opacity: 0.8;
        }

        &.collapsed {
            background: linear-gradient(to left, #000 29%, white 25%) !important;
        }
    }
}


.FeaturedImageSection-Buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .GenerateNew {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        padding: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        color: #294586;
        cursor: pointer;

        &:hover {
            color: #3F77F8;

            svg {
                path {
                    fill: #3F77F8;
                    fill-opacity: 0.8;
                }
            }
        }

        svg {
            width: 1rem;
            height: 1rem;
        }
    }

    .manual-upload-btn {
        cursor: pointer;

        &:hover {
            color: #3F77F8;
        }
    }

    svg {
        width: 1rem;
        height: 1rem;
        cursor: pointer;

        &:hover path {
            fill: #3F77F8;
            fill-opacity: 0.8;
        }
    }
}