.keyword-container {
    width: 100%;
  }
  
  .publish-to-blog-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
  
    img {
      width: 24px;
      height: auto;
    }
  }
  
  .view-on-blog-link {
    img {
      width: 24px;
      height: auto;
    }
  }
  
  .publish-container {
    display: flex;
    align-items: center;
  
    .dropdown {
      position: relative;
      display: inline-block;
  
      .dropdown-icon {
        background-color: #fff;
        color: #000;
        border: none;
        cursor: pointer;
        // margin-left: 0.1rem;
        transition: transform 0.3s ease;
      }
  
      .rotate {
        transform: rotate(180deg);
      }
  
      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        margin-top: 2px;
        margin-left: -8rem;
        overflow-y: auto;
        padding: 0 !important;
        border-radius: 8px;
  
        .dropdown-header {
          padding: 0.5rem .75rem;
          font-weight: 500;
          border-bottom: 1px solid #ddd;
        }
  
        .dropdown-item {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          padding: 0.5rem 1rem;
  
          &:hover {
            background-color: #f1f1f1;
          }
        }
  
        p {
          color: black;
          padding: 0 !important;
          margin: 0 .625rem !important;
          text-decoration: none;
          font-weight: 500;
          display: block;
          cursor: pointer;
        }
  
      }
  
      .show {
        display: block;
      }
  
    }
  
  }